import { Modal } from '@mantine/core';
import React from 'react';

import errorMemoji from '../../../Assets/error.svg';

const ErrorModal = ({ open, close, message, heading }) => {
  return (
    <Modal
      withCloseButton={false}
      onClose={close}
      opened={open}
      centered
      size={'500px'}>
      <div className='pb-10 px-10 flex flex-col justify-center items-center text-center space-y-7 text-[#54565B] dark:text-wdark-50 '>
        <img src={errorMemoji} alt='black woman with thumbs up' />
        <h2 className='font-bold text-lg'>{heading} </h2>
        <p className='text-sm '>{message} </p>

        <div className='w-32'>
          <button
            type='button'
            onClick={close}
            className='w-full font-medium rounded-xl hover:shadow-lg flex gap-4 items-center justify-center text-center p-2 outline-none ring-1 text-base bg-red-500 text-white  ring-red-500'>
            Close{' '}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
