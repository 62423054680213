import React from 'react';
import QRScanner from 'react-qr-scanner';
import { useNavigate } from 'react-router-dom';

import { Modal } from '@mantine/core';

const QrReader = ({ open, close }) => {
  const navigate = useNavigate();

  function handleQRScanError() {
    throw new Response(null, {
      status: 400,
      statusText: 'Invalid QR Code',
    });
  }

  function handleQRScanResult(data) {
    if (data) {
      close();
      navigate(`/?qr_code=${data.text}`);
    }
  }

  return (
    <Modal
      opened={open}
      onClose={close}
      centered
      size='sm'
      withCloseButton={false}>
      <QRScanner
        facingMode='rear'
        onError={handleQRScanError}
        onScan={handleQRScanResult}
        delay={1000}
        style={{ width: '100%', height: 320 }}
      />
    </Modal>
  );
};

export default QrReader;
