import React from 'react';
import { useRouteError } from 'react-router-dom';

import LandingPage from '../landing_page';

const ErrorBoundary = () => {
  const error = useRouteError();
  
  if (
    error.status === 400 ||
    error.statusText.toLowerCase().includes('invalid')
  ) {
    return (
      <LandingPage
        errorMessage={error.statusText}
        withError={true}
      />
    );
  }
  return <LandingPage />;
};

export default ErrorBoundary;
