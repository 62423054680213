import './index.css';

import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, createBrowserRouter, useLocation } from 'react-router-dom';

import { qr_code_action } from './actions/qr_code_action';
import {
  ErrorBoundary,
  Navbar,
  TraceabilityInformation,
  TraceabilityMap,
} from './components';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
    },
  },
});

function App() {
  const { search } = useLocation();

  if (!search && !search.includes('qr_code'))
    throw new Response(null, {
      status: 405,
      statusText: 'No QR Code Supplied, Scan a Code',
    });

  return (
    <div>
      <Navbar />
      <div className='lg:flex lg:justify-between w-full  lg:h-[calc(100vh-90px)] lg:overflow-y-hidden bg-[#f8f7f7]'>
        <TraceabilityMap />
        <TraceabilityInformation />
      </div>
    </div>
  );
}

function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
}

export const router = createBrowserRouter([
  {
    path: '',
    errorElement: <ErrorBoundary />,
    action: qr_code_action,
    children: [
      {
        index: true,
        element: <Root />,
      },
      {
        path: '*',
        element: (
          <Navigate
            to='/'
            replace
          />
        ),
      },
    ],
  },
]);
