import './map.css';

import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useSearchParams } from 'react-router-dom';

import locationIcon from '../../Assets/Vector.svg';
import purch from '../../Assets/farm.svg';
import { useTraceabilityQuery } from '../../query';
import { getDistanceFromLatLonInKm, getMinutes } from '../../utils/map_funcs';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage('en');

const Marker = ({ color, name }) => {
  return (
    <>
      <div
        className='pin bounce'
        style={{ backgroundColor: color, cursor: 'pointer' }}
        title={name}
      />
      <div className='pulse' />
    </>
  );
};

const TraceabilityMap = () => {
  const [searchParams] = useSearchParams();
  const qr_code = searchParams.get('qr_code');

  const { data, isLoading, isError, error } = useTraceabilityQuery(qr_code);

  if (isLoading) {
    return (
      <div className='w-full'>
        <p>Loading...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className='w-full'>
        <p>{error.message}</p>
      </div>
    );
  }

  if (!data || data.data.data.commodity.length === 0) {
    throw new Response(null, {
      status: 404,
      statusText: 'Invalid QR Code Supplied',
    });
  }

  return <MapRender traceabilityInfo={data.data.data} />;
};

function MapRender({ traceabilityInfo }) {
  const [center, setCenter] = useState({
    lat: 9.053293,
    lng: 7.497437,
  });
  const [address, setAddress] = useState('');

  const farmersPoints = traceabilityInfo.farmers[0].farm_coordinates;
  const warehousePoints = traceabilityInfo.purchased_at[0];

  const [lat, lng] = [
    warehousePoints.warehouse__latitude,
    warehousePoints.warehouse__longitude,
  ];

  const farm_lat = farmersPoints?.split(',')[0] ?? lat - 0.06;
  const farm_lng = farmersPoints?.split(',')[1] ?? lng - 0.06;

  const distance = getDistanceFromLatLonInKm({
    farmlat: farm_lat,
    farmlng: farm_lng,
    lat,
    lng,
  });

  const time = getMinutes(distance);

  useEffect(() => {
    const recalibrate = async () => {
      const response = await Geocode.fromLatLng(farm_lat, farm_lng);

      const { formatted_address } = response.results.at(-1);

      const { lat, lng } = response.results[0].geometry.location;

      setCenter(() => ({
        lat,
        lng,
      }));
      setAddress(formatted_address);
    };

    recalibrate();
  }, [farm_lat, farm_lng, address]);

  const getMapOptions = () => {
    return {
      disableDefaultUI: true,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }],
        },
      ],
    };
  };

  return (
    <div
      className='relative w-full'
      key={address}>
      <div className='flex w-[80%] xl:w-max rounded-xl absolute p-3 gap-4 z-[2] bg-white top-[20%] xl:top-[30%] text-base left-1/2 -translate-x-1/2  '>
        <div>
          <img
            src={purch}
            alt='prc'
          />
        </div>

        <div className='flex flex-col gap-4'>
          <div className='flex font-bold gap-3'>
            <img
              src={locationIcon}
              alt='prc'
            />
            <h2 className='text-[#54565B] font-bold'>Farm Location</h2>
          </div>
          <div className='flex flex-1 flex-col space-y-1'>
            <p>{address}</p>
            <p className='text-sm xl:text-base'>
              <span className='text-[#76AD94]'>{distance || '0.00'} km</span> /{' '}
              <span className='text-[#76AD94]'>{time || '0.00'} min</span> from
              Warehouse
            </p>
          </div>
        </div>
      </div>
      <div className='map'>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          }}
          center={center}
          defaultZoom={17}
          options={getMapOptions}>
          <Marker
            lat={center.lat}
            lng={center.lng}
            name='My Marker'
            color='#38CB89'
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default TraceabilityMap;
