import React from "react";
import { useSearchParams } from "react-router-dom";

import bag from "../../Assets/bag.svg";
import line from "../../Assets/dashed.svg";
import femaleimg from "../../Assets/female.png";
import img from "../../Assets/no-img.png";
import qr from "../../Assets/qr.svg";
import purchased from "../../Assets/receipt.svg";
import { useTraceabilityQuery } from "../../query";

function TraceabilityInformation() {
  const [searchParams] = useSearchParams();
  const qr_code = searchParams.get("qr_code");

  const { data, isError, error, isLoading } = useTraceabilityQuery(qr_code);

  if (isError) {
    return (
      <div className="lg:h-full  lg:w-[42.5%] font-muli lg:overflow-y-auto">
        <p>{error.message} </p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="lg:h-full  lg:w-[42.5%] font-muli lg:overflow-y-auto">
        <p>Loading...</p>
      </div>
    );
  }

  if (!data || data.data.data.commodity.length === 0) {
    throw new Response(null, {
      status: 404,
      statusText: "Invalid QR Code Supplied",
    });
  }

  const traceabilityInfo = data.data.data;

  return (
    <div className="lg:h-full  lg:w-[42.5%] font-muli lg:overflow-y-auto">
      <div
        id="TQC"
        className=" z-10  top-0 flex lg:flex  flex-col px-[20px]">
        <div className="flex-1 flex bg-white lg:w-[100%] lg:mx-auto lg:mt-[25px] flex-col h-[556px] p-[20px] rounded-3xl">
          {/*TQC */}
          <div className="mb-4 border-b-2 border-b-gray-100 ">
            <div className="bg-[#76AD94] text-white text-center py-2 font-semibold text-[16px] max-w-[220px] h-[38px] mb-[30px] rounded-xl">
              <p>{qr_code}</p>
            </div>
            <p className="text-[#54565B] text-[14px] mb-4 font-semibold">
              Purchased on{" "}
              <span className="font-thin">{traceabilityInfo.purchased_on}</span>
            </p>
            {traceabilityInfo.purchased_at?.map((item, index) => {
              return (
                <p
                  key={index}
                  className=" text-[#54565B] text-[14px] mb-4 font-semibold">
                  Purchased at{" "}
                  <span className="font-thin">{item.warehouse__name}</span>
                </p>
              );
            })}
          </div>

          <div className="mb-4 border-b-2  border-b-gray-100 ">
            <p className="text-[#7C827D] text-[12px] mb-[25px] ">Commodity</p>
            <p className="text-[#47494E] text-[14px] mb-4 font-medium ">
              {traceabilityInfo.commodity[0]}
            </p>
          </div>

          <div className="mt-[30px] mb-4">
            <p className="text-[#7C827D] text-[12px]">Farmer(s)</p>

            {traceabilityInfo.farmers?.map((farmer, index) => {
              return (
                <div className="flex flex-col gap-4 max-w-lg shadow p-3 mt-[20px] rounded-xl select-none">
                  <div
                    key={index}
                    className="flex items-start gap-3">
                    <img
                      src={""}
                      alt={farmer.gender}
                      className="rounded-xl w-12 h-12 flex-shrink-0"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        if (farmer.gender === "female")
                          currentTarget.src = femaleimg;
                        else currentTarget.src = img;
                      }}
                    />
                    <div className="text-xs grid grid-cols-1 lg:grid-cols-2 gap-3 text-gray-500 font-light flex-1">
                      <span className="text-[#707673] text-[12px] lg:col-span-2">
                        {farmer.age} years &nbsp; | &nbsp; {farmer.gender}
                      </span>
                      <p>Village: {farmer.village ?? "N/A"}</p>
                      <p>LGA: {farmer.lga_of_residence ?? "N/A"} </p>
                      <p>State: {farmer.state_of_residence ?? "N/A"}</p>
                      <p>Country: Nigeria</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        id="Activity"
        className=" top-0 flex lg:flex flex-shrink-0 flex-col px-[20px]">
        <div className="flex-1 flex bg-white lg:w-[100%] lg:mx-auto mt-[25px] mb-5 flex-col  h-[900px] p-[22px] rounded-3xl ">
          <p className="text-[#54565B] mb-5 font-semibold text-[16px]">
            Activity
          </p>

          <div className="flex flex-col gap-y-20">
            <p className="text-[#7C827D] text-lg py-4 hidden last:block">
              No activities logged.
            </p>
            {traceabilityInfo.activities.map((item, index, array) => (
              <div
                key={index}
                className="flex justify-between stroke-amber-50 text-[#7C827D] 2xl:px-5 lg:px-0 text-[11px]">
                <div className="flex gap-2 xl:gap-2 md:gap-8 relative">
                  <div className="flex flex-col items-center">
                    <img
                      src={
                        item.action.toLowerCase().includes("purchased")
                          ? purchased
                          : item.action.toLowerCase().includes("received")
                          ? qr
                          : bag
                      }
                      className="w-10 h-10 rounded-full"
                      alt="recp"
                    />
                    <img
                      src={line}
                      alt="line"
                      className={`line absolute top-full h-20 ${
                        array.length === index + 1 && "hidden"
                      }`}
                    />
                  </div>
                  <div className="flex flex-col lg:w-[160px] md:w-[270px] w-[130px]">
                    <p className="font-semibold mt-2 lg:text-[13px]">
                      {item.action}{" "}
                    </p>
                    <span className="text-[#7C827D] text-[9px] mt-2 lg:text-[11px]">
                      {item.description}
                    </span>
                  </div>
                </div>
                <p className="text-[#76AD94] text-[9px] mt-2 xl:text-[10px]">
                  {" "}
                  <span>{item.created}</span>{" "}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TraceabilityInformation;
