import { useQuery } from 'react-query';

import request from '../utils/request';

export const useTraceabilityQuery = (qr_code) => {
  return useQuery({
    queryKey: ['traceability', qr_code],
    queryFn: async () => await request.get(`traceability/info/${qr_code}`),
  });
};
