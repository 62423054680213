const deg2rad = (deg) => {
  return Number(deg) * (Math.PI / 180);
};

export const getDistanceFromLatLonInKm = ({ lat, lng, farmlat, farmlng }) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(farmlat - lat); // deg2rad below
  const dLon = deg2rad(farmlng - lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat)) *
      Math.cos(deg2rad(farmlat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return formatNumber(d);
};

export const getMinutes = (n) => {
  return formatNumber(n * 0.67);
};

export const formatNumber = (value) => {
  const val = value.toString().split(',').join('');
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(val));
};
