import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, Form } from 'react-router-dom';

import { Navbar, ErrorModal } from '..';

// import mappolyline from '../../Assets/Map.png';
import qrcode from '../../Assets/qr-code.gif';
import qrcode2 from '../../Assets/code-outline.gif';
import QrReader from '../qr_reader';

const LandingPage = ({ withError, errorMessage }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const qr_code = new URLSearchParams(search).get('qr_code');

  const [showModal, setShowModal] = useState(() => {
    if (withError) return withError;
    return false;
  });
  const [searchValue, setSearchValue] = useState(qr_code ?? '');
  const [showQR, setShowQR] = useState(false);

  return (
    <>
      <Navbar />
      <div className='bg-white flex lg:flex-row flex-col lg:h-[calc(100vh-90px)] py-20'>
        <div className='flex-1 flex flex-col p-4 md:p-12 lg:p-20 justify-around gap-10'>
          <h1 className='text-4xl lg:text-6xl font-extrabold text-gray-300 leading-[50px] lg:leading-[80px]'>
            Welcome to{' '}
            <span className='text-afexgreen'>Workbench Traceability</span>
          </h1>
          <p className='pr-4 leading-8 text-lg font-thin text-gray-700'>
            Track your commodity from farm to plate with our comprehensive
            traceability system, ensuring safety, transparency, and
            accountability. Trace the origins of commodities from harvest,
            warehouse storage, rebagging, processing as well as any distribution
            steps in between.
          </p>
          <div className='flex items-center gap-x-4'>
            <div className='' onClick={() => setShowQR(true)}>
              {/* <p>Touch to Scan QR Code</p> */}
              <img
                src={qrcode}
                alt='QR'
                className='h-16 cursor-pointer animate-none'
              />
            </div>
            <p className='font-thin text-gray-700 pr-4'>Or</p>
            <Form
              id='qr-form'
              role='search'
              onSubmit={(e) => {
                e.preventDefault();
                navigate(`/?qr_code=${searchValue}`);
              }}>
              <input
                type='text'
                name='code'
                id='code'
                placeholder='Enter Code...'
                value={searchValue}
                className='border p-3 h-11 outline-none focus:ring-afexgreen focus:ring-1'
                onChange={(event) => setSearchValue(event.currentTarget.value)}
              />
            </Form>
          </div>
        </div>
        <div className='flex-1 items-center justify-center flex'>
          <img
            src={qrcode2}
            alt='Map'
            className='h-4/5 w-4/5 object-cover object-center rounded-xl'
          />
        </div>
      </div>
      {/* <div className='py-32 bg-gray-50 flex flex-col lg:flex-row items-center px-4 md:px-10 lg:px-52 gap-8 text-gray-600'> */}
      {/* <div className='flex-1'> */}
      {/* Purchase & Warehouse Receipt */}
      {/* <div className='flex items-start cursor-default gap-4 pb-4 '>
            <div className='text-right px-2 space-y-3'>
              <p className='text-xl leading-8 font-normal'>
                Harvest & Purchase
              </p>
              <p className='text-sm leading-6'>
                From farm to plate, we ensure transparency at every step. Our
                solution tracks the origin of your agricultural produce right
                from the point of harvest and purchase.
              </p>
            </div>
            <span className='px-5 py-3 border rounded-full relative text-gray-400 after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:h-[125%] after:w-[1px] after:bg-gray-200 after:block'>
              1
            </span>
          </div> */}
      {/* Rebagging */}
      {/* <div className='flex items-start cursor-default gap-4 pb-4'>
            <div className='text-right px-2 space-y-3'>
              <p className='text-xl leading-8 font-normal'>Quality Assurance</p>
              <p className='text-sm leading-6'>
                We prioritize the quality of your produce. Our traceability
                solution ensures that every step in the value chain adheres to
                the highest quality standards.
              </p>
            </div>
            <span className='px-5 py-3 border rounded-full relative text-gray-400 after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:h-[125%] after:w-[1px] after:bg-gray-200 after:block'>
              2
            </span>
          </div> */}
      {/* <div className='flex items-start cursor-default gap-4 '>
            <div className='text-right px-2 space-y-3'>
              <p className='text-xl leading-8 font-normal'>Rebagging</p>
              <p className='text-sm leading-6'>
                With our solution, every bag of produce is tracked, ensuring
                that the right products are being used for rebagging.
              </p>
            </div>
            <span className='px-5 py-3 border rounded-full relative text-gray-400 after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:h-[125%] after:w-[1px] after:bg-gray-200 after:block'>
              3
            </span>
          </div> */}
      {/* </div> */}
      {/* <div className='flex-1 flex'>
          <img src={mappolyline} alt='Map' className='object-scale-down' />
        </div> */}
      {/* <div className='flex-1'>
          <div className='flex items-start cursor-default gap-4 pb-4 '>
            <span className='px-5 py-3 border rounded-full relative text-gray-400 after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:h-[125%] after:w-[1px] after:bg-gray-200 after:block'>
              4
            </span>
            <div className='text-left px-2 space-y-3'>
              <p className='text-xl leading-8 font-normal'>Storage</p>
              <p className='text-sm leading-6'>
                We track the storage conditions and duration of storage,
                ensuring that your products are always fresh and safe for
                consumption
              </p>
            </div>
          </div> */}
      {/* <div className='flex items-start cursor-default gap-4 pb-4'>
            <span className='px-5 py-3 border rounded-full relative text-gray-400 after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:h-[125%] after:w-[1px] after:bg-gray-200 after:block'>
              5
            </span>
            <div className='text-left px-2 space-y-3'>
              <p className='text-xl leading-8 font-normal'>
                Order & Distribution
              </p>
              <p className='text-sm leading-6'>
                Efficient order management and distribution is essential for a
                seamless value chain. We ensure that the right products are
                delivered to the right location on time
              </p>
            </div>
          </div> */}
      {/* Dispatch */}
      {/* <div className='flex items-start cursor-default gap-4 '>
            <span className='px-5 py-3 border rounded-full relative text-gray-400 after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:h-[125%] after:w-[1px] after:bg-gray-200 after:block'>
              6
            </span>
            <div className='text-left px-2 space-y-3'>
              <p className='text-xl leading-8 font-normal'>Dispatch</p>
              <p className='text-sm leading-6'>
                Track every dispatch from the point of origin to the final
                destination. With real-time updates, you can ensure that your
                products are delivered safely and on time.
              </p>
            </div>
          </div> */}
      {/* </div> */}
      {/* </div> */}
      <ErrorModal
        open={showModal}
        close={() => setShowModal(false)}
        heading='Oops an Error has occurred!!!'
        message={errorMessage}
      />
      <QrReader open={showQR} close={() => setShowQR(false)} />
    </>
  );
};

LandingPage.propTypes = {
  withError: PropTypes.bool,
  error: PropTypes.string,
};

export default LandingPage;
