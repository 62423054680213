import React from 'react';
import logo from '../../Assets/logo.svg';
import ribbon from '../../Assets/ribbon.svg';

const Navbar = () => {
  return (
    <div className='w-full flex  h-[90px] bg-white sticky font-muli  z-30  top-0 items-center'>
      <div className='w-full lg:w-[71%] flex justify-center lg:justify-start'>
        <img
          src={logo}
          alt='Workbench'
          className='h-[80px] w-[188px] lg:ml-[50px]'
        />
      </div>

      <div>
        <p className='hidden lg:block text-[#47494E] font-semibold  text-[20px]'>
          Traceability Information
        </p>
      </div>

      <img src={ribbon} className='absolute right-0 top-0' alt='AFEX' />
    </div>
  );
};

export default Navbar;
