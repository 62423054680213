import { redirect } from 'react-router-dom';

export async function qr_code_action({ request }) {
  // Get the Search Form
  const formData = await request.formData();

  // Each forminput has a name and a value. The name of the field is code
  const code = formData.get('code');

  // Redirect to the map
  return redirect(`/?qr_code=${code}`);
}
